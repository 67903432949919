<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span>{{ formTitle }}</span>
            <v-spacer></v-spacer>

            <v-text-field
              autocomplete="off"
              v-model="editedItem.statut_name"
              label="Statut"
              readonly
              @focus="$event.target.select()"
            ></v-text-field>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.code"
                    label="Code"
                    required
                    readonly
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.date_colis"
                    label="Date Chargement"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    :items="cmds_list"
                    v-model="editedItem.cmd_id"
                    :item-text="
                      (item) =>
                        item.nocmd +
                        ' - ' +
                        (item.label ? item.label + ' - ' : '') +
                        item.tier_name
                    "
                    item-value="id"
                    :label="'Commande'"
                    :readonly="!modify"
                    :clearable="modify"
                    :rules="[(v) => !!v || 'Commande obligatoire']"
                    dense
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    :items="products"
                    v-model="editedItem.produit_id"
                    :item-text="'label'"
                    item-value="produit_id"
                    :label="'Produit'"
                    :readonly="!modify"
                    :clearable="modify"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.matricule1"
                    label="Matricule Vehicule"
                    dense
                    :rules="[(v) => !!v || 'Matricule Vehicule obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.matricule2"
                    label="2eme Matricule Vehicule"
                    dense
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.etat_vehicule"
                    label="Etat du Vehicule"
                    dense
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.nom_chauff"
                    label="Nom du Chauffeur"
                    dense
                    :rules="[(v) => !!v || 'Nom du Chauffeur obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.prestataire"
                    label="Prestataire"
                    dense
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :items="[
                      { id: '1', poste: 'Poste 1' },
                      { id: '2', poste: 'Poste 2' },
                      { id: '3', poste: 'Poste 3' },
                      { id: '4', poste: 'Normal' },
                    ]"
                    :item-text="'poste'"
                    item-value="id"
                    v-model="editedItem.poste"
                    label="Poste de travail"
                    dense
                    :readonly="!modify"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    required
                    :readonly="!modify"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>

            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="save" v-if="modify">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
let today = datetime();
function pad(n) {
  return n < 10 ? "0" + n : n;
}
function datetime() {
  var today = new Date();
  var date =
    today.getFullYear() +
    "-" +
    pad(today.getMonth() + 1) +
    "-" +
    pad(today.getDate());
  var time =
    pad(today.getHours()) +
    ":" +
    pad(today.getMinutes()) +
    ":" +
    pad(today.getSeconds());

  return date + " " + time;
}
import CREATE_COLIS from "../graphql/Product/CREATE_COLIS.gql";
import UPDATE_COLIS from "../graphql/Product/UPDATE_COLIS.gql";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  name: "colisform",
  props: {
    item: Object,
    showForm: Boolean,
    cmds_list: Array,
    editer: Boolean,
  },
  data: () => ({
    progress: false,
    cmpt: 0,
    valid: true,
    modify: false,
    datepicker1: false,

    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      statut_name: "Brouillon",
      statut_id: "21",
      date_colis: null,
      colislist: [],
      docs: [],
      poste: "4",
    },
    editedItem: {},
    aff: 0,
    cmds: [],
    loading: false,
  }),

  computed: {
    formTitle() {
      return this.item.id < 0
        ? "Ajout Chargement "
        : "Chargement N° " + this.editedItem.code;
    },
    products() {
      let list = [];
      let i;
      if (this.editedItem.cmd_id)
        i = this.cmds_list.findIndex((elm) => elm.id == this.editedItem.cmd_id);
      if (i >= 0) list = this.cmds_list[i].cmddetails;
      return list;
    },
  },

  created() {},
  async mounted() {
    this.$refs.form.resetValidation();

    if (this.item) {
      if (this.item.id > 0) this.editedItem = Object.assign({}, this.item);
      else {
        this.defaultItem.date_colis = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.item.cmd_id > 0) {
          this.editedItem.cmd_id = this.item.cmd_id;
          this.editedItem.produit_id = this.item.produit_id;
        }
      }
    }

    this.modify = this.editer && this.editedItem.statut_id == "21";
  },

  methods: {
    close() {
      this.$emit("close", this.editedItem);
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
          }
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.item.id > 0) {
          let v = {
            colis: {
              id: this.editedItem.id,
              cmd_id: this.editedItem.cmd_id,
              date_colis: this.editedItem.date_colis,
              produit_id: this.editedItem.produit_id,
              comment: this.editedItem.comment,
              statut_id: this.editedItem.statut_id,
              matricule1: this.editedItem.matricule1,
              matricule2: this.editedItem.matricule2,
              etat_vehicule: this.editedItem.etat_vehicule,
              nom_chauff: this.editedItem.nom_chauff,
              prestataire: this.editedItem.prestataire,
              poste: this.editedItem.poste,

              write_uid: this.$store.state.me.id,
            },
          };
          await this.maj(UPDATE_COLIS, v, true);
          this.$emit("update");
          this.close();
        } else {
          this.editedItem.date_opr = today;
          let v = {
            colis: {
              cmd_id: this.editedItem.cmd_id,
              date_colis: this.editedItem.date_colis,
              produit_id: this.editedItem.produit_id,
              comment: this.editedItem.comment,
              statut_id: this.editedItem.statut_id,
              matricule1: this.editedItem.matricule1,
              matricule2: this.editedItem.matricule2,
              etat_vehicule: this.editedItem.etat_vehicule,
              nom_chauff: this.editedItem.nom_chauff,
              prestataire: this.editedItem.prestataire,
              poste: this.editedItem.poste,

              create_uid: parseInt(this.$store.state.me.id),
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.maj(CREATE_COLIS, v, true);
          this.$emit("update");
          if (r) this.editedItem.id = r.createColis.id;
          this.$emit("change", this.editedItem);
          this.close();
        }
      }
    },
  },
};
</script>
